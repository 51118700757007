import $ from "cash-dom";
import anime from "animejs";

export const additionalComponent = async () => {
  const $popup = $('#additional-popup');

  $popup.on('click', () => {
    $popup.toggleClass('flex hidden');
  });

  $('.additional-item').each((n, el) => {
    const $root = $(el);

    $root.on('click', () => {
      $popup.find('.content').html(JSON.parse($root.attr('data-content')));
      $popup.toggleClass('flex hidden');
    });
  });
};
