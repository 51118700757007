import $ from 'cash-dom';
import anime from "animejs";

export const examplesComponent = async () => {
  console.log('examples');
  const scroll = $('#examples-desktop-scroll').get(0);
  const $back = $('#examples-desktop-back');
  const $forward = $('#examples-desktop-forward');

  console.log('examples-2', scroll);
  if (!scroll)
    return;

  let itemsPerScroll = parseInt(scroll.getAttribute('data-items-per-scroll'));
  if (isNaN(itemsPerScroll))
    itemsPerScroll = 1;

  let count = parseInt(scroll.getAttribute('data-count'));
  count = Math.ceil(count / itemsPerScroll);

  let activeIndex = 0;
  console.log('count:', count);

  let initialId = parseInt(scroll.getAttribute('data-initial-id'));
  if (isNaN(initialId))
    initialId = null;
  else {
    activeIndex = $('#examples-desktop-scroll').index(`#example-${initialId}`);
  }

  console.log(activeIndex);

  const update = () => {
    console.log('update', activeIndex);
    if (activeIndex > 0)
      $back
        .removeClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark')
        .addClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark');
    else
      $back
        .removeClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark')
        .addClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark');
    if (activeIndex < count - 1)
      $forward
        .removeClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark')
        .addClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark');
    else
      $forward
        .removeClass('bg-white border-red text-red hover:border-red-dark hover:text-red-dark')
        .addClass('bg-transparent border-gray text-gray hover:border-gray-dark hover:text-gray-dark');

    anime({
      targets: scroll,
      left: -activeIndex * scroll.offsetWidth,
      duration: 250,
      easing: 'easeOutQuad',
    });
  };

  $back.on('click', () => {
    if (activeIndex > 0) {
      --activeIndex;
      update();
    }
  });
  $forward.on('click', () => {
    if (activeIndex < count - 1) {
      ++activeIndex;
      update();
    }
  });

  update();
};
