import $ from 'cash-dom';
import FlexSearch from 'flexsearch';

export const qnaComponent = async () => {
  const $search = $('.qna-search');
  const $items = $('.qna-item');
  if (!$search.length)
    return;

  const index = new FlexSearch.Index({
    charset: 'latin:simple',
    tokenize: 'forward',
  });

  $items.each((n, el) => {
    const $item = $(el);
    const text = $item.find('.accordion-title').text() + "\n" + $item.find('.accordion-content').text();
    index.add(n, text);
  });

  const search = (value) => {
    if (value.length < 3) {
      $items.removeClass('hidden');
      return;
    }

    $items.addClass('hidden');
    const result = index.search(value);
    result.forEach(id => $items.eq(id).removeClass('hidden'));
  };

  $search.on('input', e => search(e.target.value));
  search($search.val());
};
