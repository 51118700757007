import $ from 'cash-dom';

export const ziadostOPravnePoradenstvoFormComponent = async () => {
  $('#form-ziadost-o-pravne-poradenstvo').each((n, el) => {
    const $el = $(el);
    console.log($el);

    const $radiosType = $el.find('.input-radio-type');
    const $radiosInsuredType = $el.find('.input-radio-insured-type');

    $radiosType.eq(0).on('change', () => {
      $el.find('.section-hidden').removeClass('hidden');
      $el.find('.insured-type-title').text('Ste');
    });
    $radiosType.eq(1).on('change', () => {
      $el.find('.section-hidden').removeClass('hidden');
      $el.find('.insured-type-title').text('Poistený je');
    });

    $radiosInsuredType.eq(0).on('change', () => {
      $el.find('#insured_name_title').text('Meno, priezvisko');
      $el.find('#insured_ico_container').addClass('hidden');
      $el.find('#insured_ico').removeAttr('required');
    });
    $radiosInsuredType.eq(1).on('change', () => {
      $el.find('#insured_name_title').text('Názov spoločnosti');
      $el.find('#insured_ico_container').removeClass('hidden');
      $el.find('#insured_ico').attr('required', 'required');
    });
  });
};
