import $ from 'cash-dom';

export const tabsComponent = async () => {
  $('.tabs').each((n, el) => {
    const $root = $(el);
    const $labels = $root.find('.tabs-tab-label');
    const $targets = $root.find('.tabs-tab');

    $labels.on('click', (event) => {
      const $label = $(event.target);
      const $target = $targets.filter(`#${$label.attr('data-target-id')}`);

      $labels.removeClass('bg-black text-white').addClass('bg-gray-lighter text-gray-dark');
      $label.removeClass('bg-gray-lighter text-gray-dark').addClass('bg-black text-white');

      $targets.removeClass('flex').addClass('hidden');
      $target.removeClass('hidden').addClass('flex');
    })
  });
};
