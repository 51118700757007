import $ from 'cash-dom';
import {debounce} from "@scripts/core.js";

export const productsSectionNavComponent = async () => {
  const $sections = $('#sections');
  const $menuCheck = $('#menu-check');
  const $container = $sections.find('.sections-container');

  if ($sections.length === 0 || $menuCheck.length === 0) {
    return;
  }

  const headerHeight = 152;
  const originalMarginTopClass = 'mt-12';

  const hasOriginalMarginTop = $sections.hasClass(originalMarginTopClass);

  const $placeholder = $(`<div class="hidden"></div>`);
  $sections.after($placeholder);

  const menuHeight = $sections.outerHeight(true);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        $sections.addClass('fixed-menu');

        if (window.innerWidth >= 1024)
          $container.removeClass('container:container desktop:px-18 px-5');

        if (hasOriginalMarginTop)
          $sections.removeClass(originalMarginTopClass);

        $placeholder.removeClass('desktop:hidden');
        $placeholder.addClass('desktop:block');
        $placeholder.height(menuHeight);
      } else {
        $sections.removeClass('fixed-menu');
        $container.addClass('container:container desktop:px-18 px-5');

        if (hasOriginalMarginTop)
          $sections.addClass(originalMarginTopClass);

        $placeholder.removeClass('desktop:block');
        $placeholder.addClass('desktop:hidden');
      }
    });
  };

  const offset = hasOriginalMarginTop ? 48 : 0;

  const observer = new IntersectionObserver(observerCallback, {
    root: null,
    rootMargin: `-${headerHeight - offset}px 0px 0px 0px`,
    threshold: 0,
  });

  observer.observe($menuCheck.get(0));

  $sections.find('a').each((n, el) => {
    const $el = $(el);

    $el.on('click', (event) => {
      event.preventDefault();

      window.localStorage.setItem(
        'wuestenrot-products-scroll-position',
        $el.attr('data-to-start') ? 0 : window.scrollY
      );

      window.location.replace($el.attr('data-href'));
      return false;
    });
  });

  const oldScrollPosition = window.localStorage.getItem('wuestenrot-products-scroll-position');

  if (oldScrollPosition) {
    window.scrollTo({top: parseInt(oldScrollPosition)});
    window.localStorage.removeItem('wuestenrot-products-scroll-position');
  }

  setTimeout(() => {
    $sections.addClass('transition-colors');
    $container.addClass('transition-all');
  }, 50);
};
