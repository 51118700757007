import $ from 'cash-dom';
import Cookies from 'js-cookie';
import anime from "animejs";

export const cookiesListComponent = async () => {
  $('.cookies-list').each((n, el) => {
    const $root = $(el);
    const $labels = $root.find('.cookies-list-label');
    const $labelsRoot = $root.find('.cookies-list-labels-root');
    const $activeLabelBg = $root.find('.cookies-list-active-label-bg');
    const $targets = $root.find('.cookies-list-item');

    const toggleItem = (event) => {
      const $label = $(event.target);
      const $target = $targets.filter(`#${$label.attr('data-target-id')}`);

      $labels.removeClass('text-red font-semibold').addClass('text-gray font-normal');
      $label.addClass('text-red font-semibold').removeClass('text-gray font-normal');

      $targets.addClass('hidden');
      $target.removeClass('hidden');

      anime({
        targets: $activeLabelBg.get(),
        top: $label.offset().top - $labelsRoot.offset().top,
        duration: 250,
        easing: 'easeOutQuad',
      });
    };

    $labels.on('mouseenter', toggleItem);
  });
};

export const cookiesBarComponent = async () => {
  const $root = $('.cookies');
  const $bar = $root.find('.cookies-bar');
  const $info = $root.find('.cookies-info');
  const $settings = $root.find('.cookies-settings');

  const settings = {
    '1': false,
    '2': false,
    '4': false,
    '8': false,
  };

  const domain = $root.attr('data-domain');
  const secure = $root.attr('data-secure') === 'https';

  {
    const value = Cookies.get('cookie_policy');
    if (window.dataLayer)
      window.dataLayer.push({event: "CookiePreference" + (value ?? '15')});
  }

  const setCookie = (value) => {
    Cookies.set('cookie_policy', value.toString(), {
      domain,
      sameSite: secure ? 'None' : 'Lax',
      secure,
      expires: 365 * 10,
    });
    if (window.dataLayer)
      window.dataLayer.push({event: "CookiePreference" + value.toString()});
    $bar.toggleClass('hidden');
  };

  $root.find('.cookies-reject').on('click', () => setCookie(0));
  $root.find('.cookies-accept').on('click', () => setCookie(15));
  $root.find('.cookies-toggle-settings').on('click', () => {
    $info.toggleClass('hidden');
    $settings.toggleClass('hidden');
  });
  $root.find('.cookies-set-custom').on('click', () => {
    let value = 0;
    if (settings['1'])
      value += 1;
    if (settings['2'])
      value += 2;
    if (settings['4'])
      value += 4;
    if (settings['8'])
      value += 8;

    setCookie(value);
  });

  await cookiesListComponent();

  $root.find('.cookies-setting-input').on('change', (event) => {
    const $input = $(event.target);
    settings[$input.attr('data-setting')] = $input.prop('checked');
    console.log(settings);
  });

  if (Cookies.get('cookie_policy') === undefined) {
    $bar.toggleClass('hidden');
  }

  $root.get(0).style.display = '';

  window.CookieBar = {
    open: () => $bar.removeClass('hidden'),
    close: () => $bar.addClass('hidden'),
  };
};
