import $ from 'cash-dom';

export const accordionComponent = async () => {
  $('.accordion-item').each((n, el) => {
    const $root = $(el);
    const $content = $root.find('.accordion-content');
    const $toggle = $root.find('.accordion-toggle');
    const $iconOpen = $root.find('.accordion-icon-open');
    const $iconClosed = $root.find('.accordion-icon-closed');
    let isOpen = !!$root.attr('data-is-open');

    $toggle.on('click', () => {
      isOpen = !isOpen;

      if (isOpen) {
        $iconOpen.removeClass('hidden');
        $iconClosed.addClass('hidden');
        $root.removeClass('bg-gray-lighter border-gray-lighter text-black');
        $root.addClass('bg-white border-red text-red')
        $content.removeClass('hidden');
        $content.addClass('flex');
      } else {
        $iconClosed.removeClass('hidden');
        $iconOpen.addClass('hidden');
        $root.removeClass('bg-white border-red text-red');
        $root.addClass('bg-gray-lighter border-gray-lighter text-black');
        $content.removeClass('flex');
        $content.addClass('hidden');
      }
    });
  });
};
