import $ from 'cash-dom';

export const insureHouseholdComponent = async () => {
  const $root = $('#insure-household');

  $root.find('.insure-household-button').on('click', (event) => {
    const sectionId = event.target.getAttribute('data-section-id');
    $('.insure-household-section').addClass('hidden desktop:flex');
    $(`#${sectionId}`).removeClass('hidden desktop:flex');
    console.log(sectionId);
  });

  $root.find('.household-coverages-dropdown').each((n, el) => {
    const $root = $(el);

    $root.find('.household-coverages-dropdown-toggle').on('click', () => {
      $root.find('.household-coverages-dropdown-content').toggleClass('hidden');
    });
  });

  console.log($root);
};
