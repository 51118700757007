export const debounce = (delay, callback) => {
  let timeout = null;
  return (...args) => {
    if (timeout)
      clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      callback(...args);
    }, delay);
  };
};
