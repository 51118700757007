import $ from 'cash-dom';

export const partnersComponent = async () => {
  const $button = $('.partners-button');
  console.log($button.get());
  const $menu = $('.partners-menu');

  $button.on('click', () => {
    $menu.toggleClass('hidden flex');
  });
};
