import $ from 'cash-dom';
import {Loader} from '@googlemaps/js-api-loader';
import {mapStyle} from "@scripts/components/index/map-style.js";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {marker} from "../../../../../../../wp-includes/js/codemirror/csslint.js";
import FlexSearch from "flexsearch";
import {DateTime} from "luxon";

export const indexMapComponent = async () => {
  const loader = new Loader({
    apiKey: 'AIzaSyAc4llZeDKxSq-uCAxkE-Lv3s4QdiVv4CM',
    version: 'weekly',
    region: 'SK',
    language: 'sk',
  });
  const {LatLngBounds} = await loader.importLibrary('core');
  const {Map, InfoWindow} = await loader.importLibrary('maps');
  const {AdvancedMarkerElement, PinElement} = await loader.importLibrary('marker');

  const context = {
    fetchId: 0,
  };

  const debounce = (delay, callback) => {
    let timeout = null;
    return (...args) => {
      if (timeout)
        clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;
        callback(...args);
      }, delay);
    };
  };

  const fetchBranches = async (onUpdate, search = '') => {
    let fetchId = ++context.fetchId;

    const collection = new wp.api.collections.Pobocky();
    const data = {
      per_page: 100,
    };

    if (search.length >= 3)
      data.search = search;
    const result = await collection.fetch({data});

    onUpdate(result, collection.hasMore(), true);
    while (collection.hasMore() && result.length < 200) {
      result.push(...await collection.more());

      if (fetchId !== context.fetchId)
        return undefined;

      onUpdate(result, collection.hasMore(), false);
    }

    return result;
  };

  return Promise.all(
    $('.map').get().map(async (root) => {
      const $root = $(root);
      const $mapContainer = $root.find('.map-container');
      const $search = $root.find('.map-search-input');
      const $cards = $root.find('.map-card');
      let results = [];
      const markers = {};
      const markerResults = {};
      const position = {lat: 48.74837949102991, lng: 19.21017518456245};
      let zoom = 8;

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((geoposition) => {
          position.lat = geoposition.coords.latitude
          position.lng = geoposition.coords.longitude;
          zoom = 9;
          console.log('geolocation success', position);
          init();
        }, () => {
          console.log('geolocation error');
          init();
        });
      } else {
        console.log('geolocation disabled');
        init();
      }

      const index = new FlexSearch.Index({
        charset: 'latin:simple',
        tokenize: 'forward',
      });

      const distance = (a) => Math.pow(a.acf['latitude'] - position.lat, 2) + Math.pow(a.acf['longitude'] - position.lng, 2);

      const createCard = (result) => {
        const $card = $cardTemplate.clone();
        $card.attr('id', `map-card-${result.id}`);
        $card.attr('data-type', result.acf['is_partner'] ? 'partner' : 'contract');
        $card.find('.map-card').addClass(result.acf['is_partner'] ? 'border-2 border-blue' : 'border-2 border-red');
        $card.find('.map-card-title').html(result.title.rendered);
        $card.find('.map-card-address').html(result.acf['address'] + "<br>" + result.acf['zip_code'] + ' ' + result.acf['city'])
        $card.find('.map-card-link').attr('href', result.link)
        $card.find('.phone-link').each((n, el) => {
          const $el = $(el);
          const value = result.acf[n ? 'phone_2' : 'phone'];
          $el.attr('href', `tel:${value}`);
          $el.text(value);
        });

        const openingHourKeys = [
          '',
          'opening_hours_monday',
          'opening_hours_tuesday',
          'opening_hours_wednesday',
          'opening_hours_thursday',
          'opening_hours_friday',
          'opening_hours_saturday',
          'opening_hours_sunday',
        ];
        const openingHours = result.acf[openingHourKeys[DateTime.now().weekday]];

        $card.find('.map-card-opening-hours').text(openingHours || '');

        const times = openingHours.split('-');
        if (times.length >= 2) {
          const [start, end] = times;
          const isAfterStart = DateTime.fromFormat(start.trim(), 'H:m').diffNow().as('seconds') < 0;
          const isBeforeEnd = DateTime.fromFormat(end.trim(), 'H:m').diffNow().as('seconds') > 0;

          $card.find('.map-card-opening-hours-light').css('color', isAfterStart && isBeforeEnd ? '#419F5A' : '#E61414');
        }

        cards$[result.id] = $card;
        return $card;
      };

      const onUpdate = (newResults, hasMore, isFirst) => {
        if (isFirst)
          clusterer.clearMarkers();

        results = [...newResults];
        results.sort((a, b) => distance(a) - distance(b))
          .forEach((result, n) => {
            if (markers[result.id])
              return;

            const pin = new PinElement({
              background: '#E61414',
              borderColor: '#AF1414',
              glyphColor: '#821414',
            });
            const marker = new AdvancedMarkerElement({
              position: {
                lat: result.acf['latitude'],
                lng: result.acf['longitude'],
              },
              content: pin.element,
            });

            index.add(result.id, `${result.acf['city']} ${result.acf['address']} ${result.acf['zip_code']}`)

            const infowindow = new InfoWindow({
              content: `<strong>${result.acf['address']}</strong><br>${result.acf['city']}<br><a class="underline hover:no-underline text-red-dark font-medium" target="_blank" href="${result.link}">Detail pobočky</a>`
            });
            marker.addListener('click', () => {
              infowindow.open(map, marker);
            });
            markers[result.id] = marker;
            markerResults[result.id] = result;
            clusterer.addMarker(marker);

            if (n < $cards.length) {
              const $card = $cards.eq(n);
              $card.find('.map-card-title').text(result.acf['address']);
              $card.find('.map-card-address').text(result.acf['zip_code'] + ', ' + result.acf['city'])
              $card.find('.map-card-link').attr('href', result.link)
              $card.find('.phone-link').each((n, el) => {
                const $el = $(el);
                const value = result.acf[n ? 'phone_2' : 'phone'];
                $el.attr('href', `tel:${value}`);
                $el.text(value);
              });
            }
          });
      };

      let map, clusterer;

      const init = function () {
        console.log('init', position);
        map = new Map($mapContainer.get(0), {
          center: position,
          zoom,
          disableDefaultUI: true,
          styles: mapStyle,
          mapId: '7930465941fdbb2d',
        });

        clusterer = new MarkerClusterer({
          map,
          markers: [],
        });

        fetchBranches(onUpdate, $search.val());

        const onSearch = (e) => {
          const value = e.target.value;
          const bounds = new google.maps.LatLngBounds();

          const searchResults = index.search(value);
          if (value.length >= 3 && searchResults.length) {
            clusterer.clearMarkers();

            searchResults.forEach((id, n) => {
              const result = markerResults[id];
              const marker = markers[id];

              clusterer.addMarker(marker);
              bounds.extend(marker.position);

              if (n < $cards.length) {
                const $card = $cards.eq(n);
                $card.find('.map-card-title').text(result.acf['address']);
                $card.find('.map-card-address').text(result.acf['zip_code'] + ', ' + result.acf['city'])
                $card.find('.map-card-link').attr('href', result.link)
                $card.find('.phone-link').each((n, el) => {
                  const $el = $(el);
                  const value = result.acf[n ? 'phone_2' : 'phone'];
                  $el.attr('href', `tel:${value}`);
                  $el.text(value);
                });
              }
            });

            map.fitBounds(bounds);
          } else {
            clusterer.clearMarkers();

            results.forEach((result, n) => {
              const marker = markers[result.id];

              clusterer.addMarker(marker);
              bounds.extend(marker.position);

              if (n < $cards.length) {
                const $card = $cards.eq(n);
                $card.find('.map-card-title').text(result.acf['address']);
                $card.find('.map-card-address').text(result.acf['zip_code'] + ', ' + result.acf['city'])
                $card.find('.map-card-link').attr('href', result.link)
                $card.find('.phone-link').each((n, el) => {
                  const $el = $(el);
                  const value = result.acf[n ? 'phone_2' : 'phone'];
                  $el.attr('href', `tel:${value}`);
                  $el.text(value);
                });
              }
            });

            map.setCenter(position);
            map.setZoom(zoom);
          }
        };

        $search.on('input', debounce(500, onSearch));
      }
    })
  );
};
