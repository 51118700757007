import anime from 'animejs';
import $ from 'cash-dom';

let openMenu = null;

export const navMenuItemComponent = async () => {
  $('.nav-menu-item').each((n, el) => {
    const $root = $(el);
    const $target = $(`#${$root.attr('data-target-id')}`);
    let isOpen = false;

    const animateOpen = () => {
      $root.toggleClass('text-black text-red');
      anime({
        targets: $target.get(),
        height: 573,
        duration: 250,
        easing: 'easeOutQuad',
      });
    };

    const animateClose = (duration = 250) => {
      $root.toggleClass('text-black text-red');
      anime({
        targets: $target.get(),
        height: 0,
        duration,
        easing: 'easeOutQuad',
      });
    };

    const toggleOpen = () => {
      if (isOpen) {
        animateClose();
        isOpen = false;
        openMenu = null;
      } else {
        if (openMenu && openMenu !== $root) {
          openMenu.trigger('closeMenu');
        }

        animateOpen();
        isOpen = true;
        openMenu = $root;
      }
    };

    $root.on('closeMenu', () => {
      if (isOpen) {
        animateClose(100);
        isOpen = false;
      }
    });

    $root.on('click', () => {
      toggleOpen();
    });
  });
};

export const navMenuComponent = async () => {
  $('.nav-menu').each((n, el) => {
    const $root = $(el);
    const $labels = $root.find('.nav-menu-label');
    const $labelsRoot = $root.find('.nav-menu-labels-root');
    const $activeLabelBg = $root.find('.nav-menu-active-label-bg');
    const $targets = $root.find('.nav-menu-category');

    $labels.on('mouseenter', (event) => {
      const $label = $(event.target);
      const $target = $targets.filter(`#${$label.attr('data-target-id')}`);

      $labels.removeClass('text-black font-semibold').addClass('text-gray-dark font-normal');
      $label.addClass('text-black font-semibold').removeClass('text-gray-dark font-normal');

      $targets.addClass('hidden');
      $target.removeClass('hidden');

      anime({
        targets: $activeLabelBg.get(),
        top: $label.offset().top - $labelsRoot.offset().top,
        duration: 250,
        easing: 'easeOutQuad',
      });
      console.log($label.offset().top);
    });
  });
};

export const navMenuAnimatedMobileComponent = async () => {
  $('.nav-menu-item-mobile').each(function() {
    $(this).on('click', function() {
      const targetId = $(this).data('target-id');
      window.jQuery('#' + targetId).slideToggle();
      $(this).toggleClass('text-red');
    });
  });

  $('.nav-menu-label-mobile').each(function() {
    $(this).on('click', function() {
      var targetId = $(this).data('target-id');
      window.jQuery('#' + targetId).slideToggle();
    });
  });
}

export const headerComponent = async () => {
  const $root = $('header');

  await navMenuItemComponent();
  await navMenuAnimatedMobileComponent();
  await navMenuComponent();

  $('.header-mobile-toggle').on('click', () => {
    $('.header-mobile').toggleClass('hidden flex');
  });

  $('.my-wuestenrot').on('click', () => {
    $('.my-wuestenrot-menu').toggleClass('hidden flex');
  });
};
