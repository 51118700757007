import $ from 'cash-dom';

export const newsShortComponent = async () => {
  const container = document.querySelector('#news-short');

  if (!container)
    return;

  const fillContainer = () => {
    const containerWidth = container.offsetWidth;

    const totalCycleWidth = document.querySelector('.news-block').offsetWidth;

    let shortfallWidth = containerWidth - totalCycleWidth;
    let additionalItemsNeeded = 0;

    if (shortfallWidth > 0) {
      additionalItemsNeeded = Math.ceil(shortfallWidth / totalCycleWidth);
    }

    for (let i = 0; i < (additionalItemsNeeded + 3); i++) {
      const itemToClone = document.querySelector('.news-block');
      const clone = itemToClone.cloneNode(true);
      container.appendChild(clone);
    }
  };

  const respawnChild = () => {
    const children = Array.from(container.children);
    children.forEach((child) => {
      const childRect = child.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      if ((childRect.right + 32) < containerRect.left) {
        const clone = child.cloneNode(true);
        container.removeChild(child);
        container.appendChild(clone);
      }
    });

    requestAnimationFrame(respawnChild);
  };

  fillContainer();
  respawnChild();
};
