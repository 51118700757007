import $ from 'cash-dom';
import WPAPI from 'wpapi';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import {debounce} from "@scripts/core.js";
import {DateTime, Settings} from "luxon";

Settings.defaultLocale = 'sk';

export const fondsGraphComponent = async () => {
  const wpapi = new WPAPI({endpoint: window.wpApiSettings.root});

  $('.fonds-graph-container').each(async (n, el) => {
    const $root = $(el);
    const $groupIdSelect = $('#group_id');
    const $startDateInput = $('#start_date');
    const $endDateInput = $('#end_date');
    const $periodButtons = $('.button-period');
    const $statCurrent = $('#stat-current');
    const $statGain = $('#stat-gain');
    const $statMin = $('#stat-min');
    const $statMax = $('#stat-max');
    const canvas = $root.find('.fonds-graph').get(0);

    const getGradient = createGradient();

    const yAxisFormat = new Intl.NumberFormat('sk-SK', {
      style: 'currency',
      currency: 'EUR',
    });

    const statFormat = new Intl.NumberFormat('sk-SK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
    });
    const percentFormat = new Intl.NumberFormat('sk-SK', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });

    const config = {
      'group_id': 1,
      'start_date': DateTime.now().startOf('day').minus({months: 3}).toISODate(),
      'end_date': DateTime.now().startOf('day').toISODate(),
    };

    const fetchData = () => {
      return wpapi.url('/api/wusfonds/fonds')
        .param(config)
        .get();
    };
    const update = debounce(500, async (mode) => {
      const result = await fetchData();
      const {
        data,
        current,
        gain,
        min,
        max,
        name,
      } = result;

      chart.data.datasets[0].data = data;
      chart.data.datasets[0].label = name;
      chart.update(mode);

      console.log($statCurrent.find('.stat-value'));
      $statCurrent.find('.stat-value').text(current ? statFormat.format(current.value) : '');
      $statCurrent.find('.stat-date').text(current ? DateTime.fromISO(current.date).toLocaleString() : '');
      $statGain.find('.stat-value').text(gain ? `${percentFormat.format(gain.value * 100)} %` : '');
      $statGain.find('.stat-date').text(gain ? DateTime.fromISO(gain.date).toLocaleString() : '');
      $statMin.find('.stat-value').text(min ? statFormat.format(min.value) : '');
      $statMin.find('.stat-date').text(min ? DateTime.fromISO(min.date).toLocaleString() : '');
      $statMax.find('.stat-value').text(max ? statFormat.format(max.value) : '');
      $statMax.find('.stat-date').text(max ? DateTime.fromISO(max.date).toLocaleString() : '');
    });

    const chart = new Chart(canvas, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Fond Wüstenrot',
            fill: 'origin',
            borderColor: '#E61414',
            borderJoinStyle: 'bevel',
            borderWidth: 2,
            pointStyle: false,
            xAxisId: 'x',
            yAxisID: 'y',
            backgroundColor: function (context) {
              const chart = context.chart;
              const {ctx, chartArea} = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return;
              }
              return getGradient(ctx, chartArea);
            },
            data: [],
          }
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {display: false},
          tooltip: {
            callbacks: {
              title: function(tooltipItems) {
                return tooltipItems[0].label.split(',')[0].replace(' ', '').replace(' ', '');
              }
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month'
            }
          },
          y: {
            ticks: {
              callback: function(value) {
                return yAxisFormat.format(value)
              }
            },
          },
        },
      },
    });

    update('none');

    $groupIdSelect.on('change', (event) => {
      config.group_id = parseInt(event.target.value);
      update();
    });
    $startDateInput.on('input', (event) => {
      config.start_date = event.target.value;
      if (config.start_date.localeCompare(config.end_date) > 0) {
        $endDateInput.val(config.start_date);
        config.end_date = config.start_date;
      }
      update();
    });
    $endDateInput.on('input', (event) => {
      config.end_date = event.target.value;
      if (config.start_date.localeCompare(config.end_date) > 0) {
        $startDateInput.val(config.end_date);
        config.start_date = config.end_date;
      }
      update();
    });
    $periodButtons.each((n, el) => {
      const $button = $(el);
      const months = parseInt($button.attr('data-months'));
      $button.on('click', () => {
        const end = DateTime.now().startOf('day');
        const start = end.minus({months});
        config.start_date = start.toISODate();
        $startDateInput.val(config.start_date);
        config.end_date = end.toISODate();
        $endDateInput.val(config.end_date);
        update();
      });
    });
  });
};

const createGradient = () => {
  let width, height, gradient;

  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient.addColorStop(0, '#E6141400');
      gradient.addColorStop(1, '#E6141433');
    }

    return gradient;
  }

  return getGradient;
};
